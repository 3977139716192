var site = site || {};

(function ($) {
  Drupal.behaviors.detailsIngredientsV1 = {
    attach: function (context) {
      var template = $('script.inline-template[path="clinique_ingredient"]').html();
      var productIngredients,
        $ingredientsContent,
        $ingredients,
        $ingredientsHeader,
        $ingredientsToggle,
        $ingredientsDisclaimer,
        $ingredientsList,
        $ingredientsContainer;
      var $prodDescription = $('.abstract', context);
      var $howToUse = $('.how_to_use', context);
      var $desc = $('.desc', context);
      var $oldIngredients = $('.ingredients', context);
      var $frIngredients = $('.spp-product__details-ingredients', context);

      $ingredientsList = $('<li/>');
      $ingredientsList.addClass('ingredients_container').append(template);
      $ingredientsContainer = $('<div/>');
      $ingredientsContainer.addClass('ingredients_container').append(template);
      if (site.client.isMobile) {
        if ($howToUse.length > 0) {
          $ingredientsList.insertBefore($howToUse);
        } else {
          $ingredientsList.insertAfter($desc);
        }
      } else {
        $ingredientsContainer.insertAfter($prodDescription);
      }
      $ingredients = $('.ingredients', context);
      $ingredientsHeader = $('.ingredients__header', $ingredients);
      $ingredientsToggle = $('.ingredients__toggle', $ingredients);
      $ingredientsDisclaimer = $('.ingredients__disclaimer', $ingredients);
      if (!_.isUndefined(page_data['catalog-spp'])) {
        productIngredients = page_data['catalog-spp']['products'][0]['skus'][0]['ILN_LISTING'];
        productIngredients = productIngredients ? productIngredients.slice(12, productIngredients.length - 1) : '';
        $oldIngredients.remove();
        $frIngredients.remove();
        $ingredientsContent = $('.ingredients__content', context);
        $ingredientsContent.append(productIngredients);
        if (productIngredients.length > 0) {
          $ingredients.removeClass('hidden');
        }
        $ingredientsHeader.off('click').on('click', function () {
          if ($ingredientsContent.hasClass('hidden')) {
            $ingredientsContent.removeClass('hidden');
            $ingredientsToggle.addClass('ingredients__less').removeClass('ingredients__more');
            $ingredientsDisclaimer.removeClass('hidden');
          } else {
            $ingredientsContent.addClass('hidden');
            $ingredientsToggle.addClass('ingredients__more').removeClass('ingredients__less');
            $ingredientsDisclaimer.addClass('hidden');
          }
        });
      }
    }
  };
})(jQuery);
